@import "@/assets/styles/global/lib.scss";













































































































































































































































































































































































































































































































































































































































































































































.price-table {
  .rs-button {
    &.black-cta {
      > a {
        margin-top: size(4);
      }
    }
  }
}


.header-wagon-zone-svg, .header-wagon-zone {
  position: relative;

  a {
    border-bottom: 1px dotted var(--rs-black-color);
  }

  .link-item {
    transition: opacity 0.3 linear;
  }

  .tooltip {
    opacity: 0;
    position: absolute;
    top: size(-10);
    left: size(70);
    padding: size(6);
    z-index: 1;
    font-size: size(12);
    line-height: size(15);
    background: var(--rs-white-color);
    box-shadow: size(1) size(2) size(1) size(2) var(--rs-gray-color);
    border-radius: size(6);
    transition: opacity 0.3 linear;
  }

  .link-item:hover + .tooltip {
    opacity: 1;
  }
}
