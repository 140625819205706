@import "@/assets/styles/global/lib.scss";















































































































































































































































.committed-usage {
  color: var(--rs-black-color);
  .usage-title {
    font-size: var(--rs-title-level0-font-size);
    line-height: var(--rs-title-level0-line-height);
    font-weight: 800;
    margin-bottom: size(20);
    display: inline-block;
    max-width: size(570);
    strong {
      color: var(--rs-lavender-color);;
    }
  }
  .usage-detail-text {
    font-size: var(--rs-para-font-size);
    line-height: var(--rs-para-line-height);
    margin-bottom: size(60);
    display: inline-block;
    max-width: size(570);
    strong {
      font-weight: bold;
    }
  }
  .rs-flex-box {
    .flex-child {
      a {
        color: var(--rs-black-color);
        &:hover {
          color: var(--rs-black-color);
        }
      }
    }
  }
}
