@import "@/assets/styles/global/lib.scss";































.popup-container {
  background-color: #272727;
  width: size(217);
  padding: size(30) size(30) size(64) size(30);
  position: fixed;
  left: size(30);
  animation: slideup 1s ease-in-out;
  animation-fill-mode: forwards;
  transition: bottom 0.3s ease;
  box-shadow: 12px 12px 25px 0px rgba(0, 0, 0, 0.28);
  border-radius: size(12);
  z-index: var(--slidein-z-index);
  background-image: url('../assets/ipl-banner-img.svg'),
  url('../assets/ipl-banner-img3.svg'), url('../assets/ipl-banner-image2.svg');
  background-position: top 2px left 0, left 0 bottom 0,  right 0 bottom 0;
  background-repeat: no-repeat;

  @keyframes slideup {
    0%{
      bottom: size(-200);
    }
    100%{
      bottom: size(20);
    }
  }
  h2 {
    padding: 0;
    font-size: size(32);
    font-weight: 700;
    color: #F7FD8B;
    line-height: size(36);
    font-family: var(--rz-para-font);

    span {
      display: block;
    }
  }

  .auction-tag {
    font-size: size(16);
    font-weight: 400;
    font-family: var(--rz-para-font);
    color: #fff;
    margin-top: size(6);
    line-height: size(22);
  }

  .time-container {
    display: flex;
    gap: size(28);
    margin-top: size(30);
    margin-bottom: size(45);
    >div {
      display: flex;
      flex-direction: column;
      text-align: center;
      h3 {
        color: #AB94F1;
        font-size: size(40);
        font-weight: 700;
        line-height: size(44);
      }

      p {
        color: #EAEAEA;
        font-size: size(12);
        font-weight: 600;
        line-height: size(15);
        font-family: var(--rz-para-font);
      }
    }

  }

  a {
    padding: size(10) size(18);
    color: var(--rs-black-color);
    margin-bottom: size(48);
    border-radius: size(4);
    background: #F7FD8B;
    cursor: pointer;
    font-size: size(14);
    font-weight: 600;
    line-height: size(14);
  }

  .img-blk {
    position: absolute;
    top: size(12);
    right: size(12);
    cursor: pointer;
  }

  @media screen and (min-width: $breakpoint-md) {
    h2 {
      font-size: size(36);
      line-height: size(40);
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    h2 {
      font-size: size(48);
      line-height: size(52);
    }
  }
}
