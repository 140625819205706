@import "@/assets/styles/global/lib.scss";

































































#all-svg {
  display: none;
}
