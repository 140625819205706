@import "@/assets/styles/global/lib.scss";











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.rs-home {
  #package-based {
    padding-top: size(60);
    .title {
      .p {
        @media screen and (min-width: $breakpoint-lg) {
          font-weight: 800;
          font-size: size(80);
          line-height: size(90);
          padding-bottom: size(20)
        }
        strong {
          color: #4F37C8;
        }
      }
    }
  }
  .content-section {
    max-width: size(550);
    padding: 0 0 size(30);
    @media screen and (min-width: $breakpoint-md) {
      padding-top: 0;
      padding-bottom: size(60);
    }
    .markdown {
      .p {
        font-weight: bold;
        font-size: var(--rs-title-level2-font-size);
        line-height: var(--rs-title-level2-line-height);
        padding-bottom: size(10);
        a {
          color: var(--hero-text);
        }
        strong {
          font-weight: bold;
        }
      }
    }
    .content {
      .p {
        font-size: size(16);
        line-height: size(24);
        font-weight: normal;
        padding-bottom: 0;
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(18);
          line-height: size(28);
        }
        strong {
          font-weight: bold;
        }
      }
    }
  }
  .popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1000;
    .popup {
      position: fixed;
      z-index: 1000;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      border-radius: size(2);
      width: size(300);
      height: size(450);
      overflow: scroll;
      @media screen and (min-width: $breakpoint-md) {
        width: size(680);
        height: auto;
        overflow: auto;
      }
      .popup-header {
        svg {
          position: absolute;
          right: size(20);
          top: size(20);
          width: size(10);
          height: size(10);
          cursor: pointer;
        }
      }
    }
  }
  .match-box {
    padding: size(20) 0;
    border-bottom: size(1) solid #3F3F3F;
    .link-button {
      .link-plain {
        font-size: var(--rs-title-level5-font-size);
        line-height: var(--rs-title-level5-line-height);
        text-transform: uppercase;
        color: var(--rs-light-lavender-color);
      }
    }
    &:nth-child(2n) {
      .link-plain {
        color: var(--rs-orange-color);
      }
    }
    &:nth-child(3n) {
      .link-plain {
        color: var(--rs-light-pink-color);
      }
    }
    &:nth-child(4n) {
      .link-plain {
        color: var(--rs-light-green-color);
      }
    }
    &:nth-child(5n) {
      .link-plain {
        color: var(--rs-blue-color);
      }
    }
  }
  .cricket-apis-wrap {
    .hero-title {
      strong {
        color: var(--rs-pink-color);
      }
    }
  }
  .pay-go-wrap {
    .hero-title {
      strong {
        color: var(--rs-lavender-color);
      }
    }
  }
  .tech-process-wrap {
    .hero-title {
      strong {
        color: var(--rs-red-color);
      }
    }
    .flex-item {
      padding-right: size(30);
      padding-bottom: size(40);
    }
    .rs-flex-box {
      padding-top: size(40);
    }
    @media screen and (min-width: $breakpoint-md) {
      padding-bottom: size(30);
      .flex-item {
        flex: 0 0 30%;
      }
    }
  }
  .we-spear-your-language {
    .hero-title {
      strong {
        color: var(--rs-green-color);
        &:after {
          content: '';
          display: inline-block;
          background-image: url(../../assets/rsCricket/speak.svg);
          width: size(32);
          height: size(70);
          background-repeat: no-repeat;
          background-size: contain;
          vertical-align: middle;
          margin-left: size(10);
          @media screen and (min-width: $breakpoint-md) {
            height: size(103);
          }
        }
      }
    }
  }
  .committed-usage {
    .rs-container {
      padding-top: size(0);
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(100);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-bottom: size(160);
      }
    }
  }
  .kabbadiapi-banner {
    .rs-container {
      @media screen and (min-width: $breakpoint-lg) {
        padding: 0;
      }
      .container {
        background: linear-gradient(180deg, #081953 0%, #1F52A5 100%);
        padding-top: 0;
        border-radius: size(6);
      }
      .wrapper {
        background: url('../../assets/arena.svg') no-repeat;
        background-size: 100%;
        position: relative;
        background-position: center;
        @media screen and (min-width: $breakpoint-md) {
          background-position: top;
        }
        .content-wrapper {
          display: inline-block;
          vertical-align: top;
          padding: size(50) size(20);
          padding-bottom: size(180);
          @media screen and (min-width: $breakpoint-md) {
            width: size(310);
            padding: size(60) size(20);
            padding-right: 0;
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(310);
            padding: size(65) size(60);
            padding-right: 0;
          }
          h6 {
            font-size: size(16);
            line-height: size(19);
            color: var(--rs-white-color);
            padding-bottom: size(8);
          }
          .hero-title {
            .p {
              font-weight: bold;
              font-size: size(30);
              line-height: size(42);
              padding-bottom: size(20);
              padding-right: 0;
              color: var(--rs-white-color);
              @media screen and (min-width: $breakpoint-md) {
                font-size: size(32);
                line-height: size(39);
              }
            }
          }
          .hero-desc {
            .p {
              font-size: size(20);
              line-height: size(28);
              color: var(--rs-white-color);
              padding-bottom: size(20);
              @media screen and (min-width: $breakpoint-md) {
                padding-bottom: size(36);
              }
              strong {
                font-weight: bold;
              }
            }
          }
          .rs-button {
            a {
              margin-top: 0;
              background: var(--rs-white-color);
              color: #181818;
              border: none;
            }
          }
        }
        .image-wrapper {
          position: absolute;
          bottom: -8%;
          left: 0;
          @media screen and (min-width: $breakpoint-md) {
            left: 28%;
          }
          @media screen and (min-width: size(1024)) {
            left: 30%;
          }
          img {
            display: inline-block;
            vertical-align: bottom;
            height: size(200);
            @media screen and (min-width: $breakpoint-md) {
              width: size(430);
            }
            @media screen and (min-width: $breakpoint-lg) {
              width: size(525);
            }
          }
        }
      }
    }
  }
  .graphql-promo {
    .rs-container {
      .wrapper {
        .content-wrapper {
          .markdown {
            font-size: 0;
            line-height: 0;
            .p {
              font-size: size(16);
              line-height: size(24);
              font-weight: normal;
              padding-bottom: size(20);
              color: var(--rs-white-color);
              @media screen and (min-width: $breakpoint-lg) {
                padding-bottom: size(40);
              }
              em {
                color: #F5FF78;
              }
            }
          }
          .text-button {
            &.rs-button {
              .link {
                background: transparent;
                color: var(--rs-white-color);
                border-color: var(--rs-white-color);
                margin-top: 0;
                padding: size(12);
                @media screen and (min-width: $breakpoint-md) {
                  padding: size(14) size(24);
                }
                &:after {
                  border-color: var(--rs-white-color);
                }
              }
            }
          }
        }
      }
    }
  }
  .press-mentions {
    .image-wrap {
      &.press-image {
        .image-item {
          text-align: left;
        }
      }
    }
  }
  .rs-cricket-banner {
    .hero-links {
      .text-button {
        >a {
          margin-top: size(12);
          @media screen and (min-width: $breakpoint-md) {
            margin-top: size(18);
          }
        }
      }
    }
  }
  .rs-support-block {
    .rs-container {
      @media screen and (min-width: $breakpoint-md) {
        margin-top: size(60);
      }
      @media screen and (min-width: $breakpoint-lg) {
        margin-top: size(100);
      }
    }
  }
  .graph-promotion {
    .title {
      &.markdown {
        display: inline-block;
        color: var(--rs-black-color);
        font-size: size(30);
        font-weight: 800;
        line-height: size(44);
        padding-bottom: size(24);
        max-width: size(670);
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(60);
          line-height: size(74);
        }

        strong {
          color: var(--rs-lavender-color);
        }
      }
    }
    .graph-contents {
      display: block;
      @media screen and (min-width: $breakpoint-md) {
        padding-top: size(40);
        display: flex;
        flex-wrap: wrap;
        gap: size(20);
      }
      @media screen and (min-width: $breakpoint-lg) {
        background-image: url('~@/assets/graph-bg.svg');
        background-repeat: no-repeat;
        background-position: top 0 right size(200);
        gap: size(36);
      }

      >a {
        border-radius: size(6);
        border: 1px solid #F4F3F3;
        background: var(--rs-white-color);
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
        padding: size(24);
        position: relative;
        z-index: 100;
        margin-bottom: size(20);
        display: block;
        @media screen and (min-width: $breakpoint-md) {
          flex: 0 0 40%;
          margin-bottom: 0;
        }
        @media screen and (min-width: $breakpoint-lg) {
          flex: 0 0 size(190);
        }

        h3 {
          color: var(--rs-black-color);
          font-size: size(20);
          font-weight: 700;
          padding-bottom: size(8);
        }
        a {
          font-size: size(12);
          font-weight: 400;
          line-height: size(14);
          text-decoration-line: underline;
          margin-bottom: size(200);
          display: inline-block;
        }

        .image-item {
          position: absolute;
          bottom: size(16);
          right: 0;
          height: size(155);
          width: auto;
        }
        &.section-3 {
          .image-item {
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }
  .client-testimonials-wrap {
    .title-wrapper {
      display: flex;
      justify-content: space-between;
      .hero-title {
        strong {
          color: var(--rs-lavender-color);
        }
      }
      img {
        display: none;
        @media screen and (min-width: $breakpoint-lg) {
          display: inline-block;
          max-width: size(320);
        }
      }
    }
    .testimonial-wrapper {
      display: flex;
      align-items: start;
      justify-content: center;
      gap: size(16);
      flex-wrap: wrap;
      padding-top: size(20);
      flex-direction: column;
      @media screen and (min-width: $breakpoint-md) {
        flex-direction: row;
        padding-top: size(40);
      }
      .testimonial {
        border-radius: size(18);
        background: #FFF;
        box-shadow: 0px 0px 30px 0px rgba(141, 151, 158, 0.20);
        padding: size(24);
        @media screen and (min-width: $breakpoint-md) {
          max-width: size(300);
        }
        @media screen and (min-width: $breakpoint-lg) {
          max-width: size(210);
        }
        .title-container {
          display: flex;
          align-items: center;
          gap: size(12);
          .image-item {
            max-width: size(20);
            img {
              max-height: size(20);
            }
            @media screen and (min-width: $breakpoint-md) {
              max-width: size(40);
              img {
                max-height: size(40);
              }
            }
          }
          .title {
            strong {
              display: block;
              font-size: size(12);
              font-weight: 400;
              line-height: normal;
              padding-top: size(4);
            }
          }
        }
        .desc {
          display: block;
          padding-top: size(20);
          border-top: 1px solid #ECECEC;
          margin-top: size(20);
          font-size: size(16);
          font-weight: 400;
          line-height: size(24);
        }
      }
    }
  }
}
