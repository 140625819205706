@import "@/assets/styles/global/lib.scss";

































































































.get-started-step {
  .image-wrapper {
    .image-item {
      .placeholder-data {
        text-align: left;
        display: none;
      }
    }
  }
}
