@import "@/assets/styles/global/lib.scss";







































































































































.contact-page {
  font-family: var(--rz-hero-font);
  color: var(--rs-black-color);
  .contact-form {
    position: absolute;
    right: 0;
    left: 0;
    top: size(310);
    margin: 0 size(20);
    @media screen and (min-width: $breakpoint-md) {
      left: auto;
      right: 0;
      transform: translateY(-80%);
      top: 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      right: 0;
      transform: translateY(-70%);
    }
  }
  .image-wrap {
    max-width: size(1000);
    margin: 0 auto;
  }
  .help-text {
    max-width: size(1080);
    margin: 0 auto;
    margin-top: size(280);
    padding: size(20);
    @media screen and (min-width: $breakpoint-md) {
      padding: size(20);
      margin-bottom: size(70);
      margin-top: 0;
      position: relative;
    }
    .container {
      @media screen and (min-width: $breakpoint-md) {
        display: inline-block;
        width: 50%;
      }
      h4 {
        font-weight: bold;
        font-size: size(24);
        line-height: size(34);
        margin-bottom: size(20);
        color: var(--rs-black-color);
      }
      svg {
        display: inline-block;
        vertical-align: top;
        margin-right: size(10);
        margin-top: size(5);
        width: size(18);
        height: size(18);
      }
      .markdown {
        display: inline-block;
        font-weight: normal;
        width: 90%;
        font-size: size(18);
        line-height: size(28);
        padding-bottom: size(15);
        color: var(--rs-black-color);
      }
    }
  }
}
