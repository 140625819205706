@import "@/assets/styles/global/lib.scss";



































































































































































































































































































































































































img {
  max-width: 100%;
}
.rs-resource-home {
  font-family: var(--rz-hero-font);
  h6 {
    &.flag-text {
      font-size: size(14);
      line-height: size(20);
      color: var(--rs-white-color);
      margin-bottom: size(5);
      clip-path: polygon(0% 0%, 100% 0%, 90% 50%, 100% 100%, 0% 100%);
      background: #97E6AB;
      text-transform: uppercase;
      -webkit-clip-path: polygon(0% 0%, 100% 0%, 90% 50%, 100% 100%, 0% 100%);
      width: size(82);
      font-size: size(10);
      line-height: size(14);
      font-weight: 600;
      color: black;
      padding: size(3) size(16) size(3) size(9);
    }
  }
  .match-wrap {
    background: var(--rs-white-color);
    box-shadow: 0 size(4) size(20) rgba(0, 0, 0, 0.08);
    border-radius: size(6);
    max-width: size(330);
    padding: size(28);
    color: var(--rs-black-color);
    align-self: flex-start;
    margin: 0 size(20);
    order: 1;
    &_box {
      display: inline-block;
      width: calc(100% / 3);
      .inline {
        font-size: size(15);
        line-height: size(21);
        font-weight: bold;
      }
      label {
        font-size: var( --rs-sm-label-font-size);
        line-height: var( --rs-sm-label-line-height);
        padding-bottom: size(4);
        display: block;
        color: #585757;
      }
    }
    &_currency {
      font-size: var(--rs-title-level3-font-size);
      line-height: var(--rs-title-level3-line-height);
      color: var(--rs-black-color);
      font-weight: bold;
    }
    &_per-month {
      font-size: var( --rs-sm-label-font-size);
      line-height: var( --rs-sm-label-line-height);
      .strike-price {
        font-size: var( --rs-sm-label-font-size);
        line-height: var( --rs-sm-label-line-height);
      }
      &.color-gray {
        color: var(--rs-gray-color);
        padding-top: size(18);
        &.two {
          padding-bottom: size(10);
        }
        span {
          color: var(--rs-black-color);
          font-weight: bold;
          display: inline-block;
          &.strike-price {
            padding-bottom: 0;
          }
        }
      }
    }
    .pricing-wrapper {
      .unit-label {
        font-weight: 400;
        font-size: size(15);
        line-height: size(21);
        color: #777777;
        padding-top: size(5);
      }
      table {
        width: 100%;
        margin-top: size(20);
        thead {
          tr {
            text-align: left;
            th {
              a {
                font-size: size(13);
                line-height: size(18);
                color: var(--hero-text);
                font-weight: bold;
              }
            }
          }
        }
        tbody {
          tr {
            &:first-child {
              td {
                padding: size(14) 0 size(20);
              }
            }
            td {
              padding: size(20) 0;
              padding-right: size(5);
              border-bottom: size(1) solid #DADADA;
              @media screen and (min-width: $breakpoint-md) {
                padding: size(20) 0;
              }
              a {
                font-weight: 400;
                font-size: size(12);
                line-height: size(17);
                color: #585757;
                padding-bottom: size(6);
                display: block;
                width: size(42);
                &:hover {
                  text-decoration: underline;
                }
              }
              h5 {
                font-weight: 400;
                font-size: size(12);
                line-height: size(17);
                color: #585757;
                padding-bottom: size(6);
              }
              p {
                font-weight: 700;
                font-size: size(13);
                line-height: size(18);
                color: var(--rs-black-color);
              }
              .free-card {
                color: #00A656;
                padding: size(1) size(8);
                background: rgba(151, 230, 171, 0.2);
                border-radius: size(3);
                font-size: size(12);
                line-height: size(17);
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    @media screen and (min-width: size(1024)) {
      position: sticky;
      top: 80px;
      margin: size(75) 0 0 0;
    }
  }
  .what-can-build-wrap {
    .image-wrap {
      height: size(400);
    }
  }
  .rs-support-block {
    .rs-container {
      @media screen and (min-width: $breakpoint-lg) {
        padding-bottom: size(35);
      }
    }
  }
  .discount-banner {
    .rs-container {
      @media screen and (min-width: $breakpoint-lg) {
        padding-top: size(35);
      }
    }
  }
  .pad-15 {
    padding: size(15) size(20);
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(15) 0;
    }
  }
  .docs-wrapper {
    border-bottom: size(1) dashed var(--rs-gray-color);
    padding-bottom: size(30);
  }
  .max-width-600 {
    max-width: size(600);
  }
  .top-padding {
    padding-top: size(30);
  }
  h4 {
    font-size: var(--rs-title-level1-font-size);
    line-height: var(--rs-title-level1-line-height);
    color: var(--rs-white-color);
    font-weight: bold;
    &.pink-color {
      color: var(--rs-pink-color);
      max-width: size(600);
      padding-top: size(30);
    }
  }
  h5 {
    font-size: var(--rs-title-level2-font-size);
    line-height: var(--rs-title-level2-line-height);
    color: var(--rs-black-color);
    font-weight: bold;
  }
  h6 {
    font-size: var(--rs-title-level4-font-size);
    line-height: var(--rs-title-level4-line-height);
    font-weight: bold;
    color: var(--rs-black-color);
    font-family: var(--rz-hero-font);
    padding-bottom: size(10);
  }
  .detail-text {
    color: var(--rs-black-color);
    font-size: var(--rs-title-level4-font-size);
    line-height: size(25);
  }
  .pay-go-wrap {
    border-top: size(1) dashed var(--rs-gray-color);
  }
}

.change-order {
  order: 2;
  padding-top: size(30);
  @media screen and (min-width: size(1024)) {
    order: 1;
    padding-top: 0;
  }
}

.rs-container {
  &.part-first {
    position: relative;
    padding: 0 0 size(50) 0;
  }
}
