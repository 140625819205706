@import "@/assets/styles/global/lib.scss";

















































































































































































































































































































































































































































































































































































































































body {
  position: relative;
}
.rs-graphQL {
  font-family: var(--rz-hero-font);
  h2 {
    font-size: size(26);
    line-height: size(30);
    font-weight: 800;
    padding: 0 0 size(20);
    max-width: 100%;
    color: var(--rs-white-color);
    @media screen and (min-width: $breakpoint-md) {
      font-size: size(30);
      line-height: size(42);
    }
    @media screen and (min-width: $breakpoint-lg) {
      font-size: size(60);
      line-height: size(74);
    }
  }
  #code-container {
    background: var(--rs-white-color);
    transition: all 0.8s linear;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(50) 0;
    }
  }
  .code-wrapper {
    .pricing-div {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      @media screen and (min-width: $breakpoint-lg) {
        max-width: size(580);
      }
      h3 {
        font-weight: bold;
        font-size: size(18);
        line-height: size(25);
        padding: 0 0 size(20);
        display: none;
        @media screen and (min-width: $breakpoint-lg) {
          padding: size(45) 0 size(40);
          display: block;
        }
      }
      table {
        width: 100%;
        display: none;
        @media screen and (min-width: $breakpoint-lg) {
          display: table;
          margin-bottom: size(26);
        }
        thead {
          tr {
            th {
              font-weight: bold;
              font-size: size(15);
              line-height: size(21);
              padding: size(15) 0;
              padding-right: size(10);
              border-bottom: size(1) solid #DADADA;
              text-align: left;
              &:first-child {
                padding-right: 0;
                padding-left: size(10);
              }
              &.last-child {
                padding-right: 0;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              font-size: size(15);
              line-height: size(21);
              padding: size(11) 0;
              border-bottom: size(1) solid #DADADA;
              cursor: pointer;
              text-align: left;
              transition: color 0.5s linear, border-color 0.5s linear;
              span {
                display: inline-block;
                vertical-align: top;
                padding: size(5);
                padding-right: size(20);
              }
              &:first-child {
                width: size(135);
                padding-right: 0;
                @media screen and (min-width: $breakpoint-md) {
                  width: size(180);
                }
                span {
                  border-left: size(2) solid transparent;
                  padding-left: size(10);
                  font-weight: 500;
                  &.unit-info {
                    font-size: size(11);
                    line-height: size(15);
                    font-weight: normal;
                    color: gray;
                    padding-top: 0;
                  }
                }
              }
              &.price {
                padding-right: size(10);
                span {
                  padding: size(5);
                  &.strike-price {
                    font-size: size(12);
                  }
                }
              }
            }
            &.active {
              td {
                span {
                  color: var(--rz-link-color);
                  &.unit-info {
                    color: var(--rz-link-color);
                  }
                }
                &:first-child {
                  span {
                    border-left: size(2) solid var(--rz-link-color);
                  }
                }
              }
            }
          }
        }
      }
    }
    .code-div {
      display: none;
      @media screen and (min-width: $breakpoint-lg) {
        width: size(380);
        margin-left: size(90);
        display: inline-block;
        vertical-align: top;
        position: relative;
        z-index: 1;
        padding: size(45) size(10);
      }
      code {
        background: #23313E;
        border-radius: size(6);
        padding: size(20);
        font-size: size(12);
        line-height: size(15);
        display: block;
        color: var(--rs-white-color);
        position: relative;
        z-index: 3;
        transition: transform 0.8s linear, display 0.5s linear;
        span {
          display: inline-block;
          padding: size(2) 0;
          &.brace {
            color: #4F97F1;
          }
          &.value {
            color: #A695FF;
          }
          &.key {
            color: #F5FF78;
          }
        }
        .first-step {
          height: size(460);
          overflow-y: scroll;
          scrollbar-width: none;
          -ms-overflow-style: none;
          &::-webkit-scrollbar {
            display: none;
          }
          scroll-behavior: smooth;
        }
        .inner-content {
          padding-left: size(12);
        }
        p {
          padding: size(4) 0;
        }
      }
      #highlight {
        padding: size(15);
        background: var(--rz-link-color);
        color: var(--rs-white-color);
        border-radius: size(3);
        position: absolute;
        left: 0;
        right: 0;
        z-index: -1;
        width: 100%;
        will-change: transform;
        transform: translate(-#{size(15)}, 0);
        transition: transform 0.5s ease;
      }
    }
  }
  .intro-section {
    position: relative;
    .rs-container {
      padding: size(50) size(20) 0;
      @media screen and (min-width: $breakpoint-md) {
        padding: size(80) size(20) 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(180) 0 0;
      }
      .wrapper {
        position: relative;
      }
      .content-wrapper {
        max-width: size(620);
        padding-bottom: size(230);
        @media screen and (min-width: $breakpoint-md) {
          padding-bottom: size(345);
        }
        h2 {
          padding: 0 0 size(20);
          max-width: 100%;
          color: var(--rs-black-color);
        }
        p {
          font-size: var(--rz-para-hero-font-size);
          line-height: var(--rz-para-hero-line-height);
        }
      }
      .image-wrapper {
        position: absolute;
        bottom: size(-45);
        right: size(20);
        z-index: -1;
        height: size(300);
        @media screen and (min-width: $breakpoint-md) {
          right: size(-20);
          height: size(350);
          bottom: size(-25);
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(450);
          bottom: size(-27);
        }
        img {
          width: size(400);
          @media screen and (min-width: $breakpoint-md) {
            width: size(550);
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(900);
          }
        }
      }
    }
  }
  .summary-section {
    .rs-container {
      padding: size(50) size(20);
      @media screen and (min-width: $breakpoint-md) {
        padding: size(80) size(20);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(180) 0;
      }
      h2 {
        padding: 0 0 size(20);
        max-width: size(600);
        color: var(--rs-black-color);
      }
      .content-wrapper {
        display: inline-block;
        vertical-align: top;
        @media screen and (min-width: $breakpoint-md) {
          max-width: 50%;
        }
        @media screen and (min-width: $breakpoint-lg) {
          max-width: size(480);
        }
        p {
          font-size: size(18);
          line-height: size(28);
        }
      }
      .image-wrapper {
        display: inline-block;
        vertical-align: top;
        margin-top: size(20);
        height: size(225);
        @media screen and (min-width: $breakpoint-md) {
          width: 40%;
          margin-left: size(20);
          margin-top: 0;
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(407);
          width: 50%;
        }
        img {
          @media screen and (min-width: $breakpoint-lg) {
            width: size(538);
          }
        }
      }
    }
  }
  .features-section {
    background: #FEC834;
    .rs-container {
      padding: size(50) size(20);
      @media screen and (min-width: $breakpoint-md) {
        padding: size(80) size(20);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(120) 0;
      }
      .wrapper {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        @media screen and (min-width: $breakpoint-lg) {
          max-width: size(520);
        }
        span {
          display: inline-block;
          vertical-align: top;
          width: size(40);
          height: size(40);
          background: url('../../assets/features-1.svg') no-repeat;
          margin-bottom: size(10);
        }
        h2 {
          font-weight: bold;
          font-size: size(26);
          line-height: size(30);
          padding-bottom: size(10);
          padding-right: 0;
          color: var(--rs-black-color);
          @media screen and (min-width: $breakpoint-md) {
            font-size: size(30);
            line-height: size(42);
          }
        }
        p {
          font-size: size(18);
          line-height: size(28);
        }
        &.features-wrapper-1 {
          margin-top: size(40);
          @media screen and (min-width: $breakpoint-lg) {
            margin-left: size(40);
            margin-top: 0;
          }
          span {
            background: url('../../assets/features-2.svg') no-repeat;
          }
        }
      }
    }
  }
  .code-section {
    position: relative;
    .rs-container {
      position: relative;
      padding: size(50) size(20) 0;
      @media screen and (min-width: $breakpoint-md) {
        padding: size(80) size(20) 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(180) 0 0;
      }
      .content-wrapper {
        max-width: size(600);
        h2 {
          padding: 0 0 size(20);
          color: var(--rs-black-color);
        }
        p {
          font-size: var(--rz-para-hero-font-size);
          line-height: var(--rz-para-hero-line-height);
        }
      }
    }
  }
  .explore-section {
    .rs-container {
      padding: size(50) size(20) 0;
      @media screen and (min-width: $breakpoint-md) {
        padding: size(80) size(20) 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(180) 0 0;
      }
      .content-wrapper {
        display: inline-block;
        vertical-align: top;
        @media screen and (min-width: $breakpoint-md) {
          max-width: 50%;
        }
        @media screen and (min-width: $breakpoint-lg) {
          max-width: size(620);
        }
        h2 {
          padding: 0 0 size(20);
          max-width: size(600);
          color: var(--rs-black-color);
        }
      }
      .image-wrapper {
        display: inline-block;
        vertical-align: top;
        margin-top: size(20);
        height: size(300);
        @media screen and (min-width: $breakpoint-md) {
          width: 47%;
          margin-left: size(20);
          margin-top: 0;
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(480);
          width: 42%;
        }
        img {
          @media screen and (min-width: $breakpoint-md) {
            width: 47%;
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(429);
          }
        }
      }
    }
  }
  .tech-process-wrap {
    .rs-container {
      padding: size(50) size(20) 0;
      @media screen and (min-width: $breakpoint-md) {
        padding: size(80) size(20) 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(180) 0 0;
      }
      .rs-flex-box {
        display: flex;
        flex-wrap: wrap;
      }
      .flex-item {
        padding-right: size(30);
        padding-bottom: size(40);
        @media screen and (min-width: $breakpoint-md) {
          flex: 0 0 45%;
        }
        @media screen and (min-width: $breakpoint-lg) {
          flex: 0 0 30%;
        }
        h1 {
          font-weight: bold;
          font-size: size(30);
          line-height: size(42);
          padding-bottom: size(10);
        }
        p {
          font-size: size(18);
          line-height: size(25);
        }
      }
    }
  }
}
