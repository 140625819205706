@import "@/assets/styles/global/lib.scss";
























.support-wrap-outer {
  background: var(--rs-dark-blue-color);
  color: var(--rs-white-color);
  padding: size(60) size(20);
  border-radius: size(6);
  .rs-hero-title {
    padding-bottom: size(30);
    color: var(--rs-white-color);
  }
  h3 {
    font-size: var(--rs-title-level0-font-size);
    line-height: var(--rs-title-level0-line-height);
    font-weight: bold;
    color: var(--rs-black-color);
    padding-right: size(30);
  }
  p {
    font-size: var(--rs-title-level4-font-size);
    line-height: var(--rs-title-level4-line-height);
    max-width: size(440);
    padding-bottom: size(20);
  }
  .rs-flex-box {
    justify-content: space-between;
  }
  .support-wrap {
    padding-top: size(30);
    flex: 0 0 100%;
  }
  .image-wrap {
    max-width: size(160);
    max-height: size(220);
    position: absolute;
    top: size(-100);
    right: size(40);
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  @media screen and (min-width: $breakpoint-md) {
    .image-wrap {
      max-width: size(240);
      max-height: size(300);
    }
    .flex-item {
      flex: 0 0 56%;
    }
    .support-wrap {
      flex: 0 0 44%;
      padding-top: size(100);
    }
  }
  @media screen and (min-width: $breakpoint-lg) {
    padding: size(60);
  }
}
