@import "@/assets/styles/global/lib.scss";



























































































































.content-image-wrap {
  &.discount {
     .rs-hero-title {
       position: relative;
       strong {
         color: #FFC220;
       }
       code {
         font-weight: 400;
         font-size: size(20);
         position: absolute;
         bottom: size(35);
       }
     }
     .detail-text {
       strong {
         font-weight: bold;
       }
     }
     .sub-text {
       strong {
         text-decoration: underline;
       }
     }
   }
 }
