@import "@/assets/styles/global/lib.scss";










































































































































































































































































































































































































































































































































































































































































































































.rs-cricket-license {
  font-family: var(--rz-hero-font);
}
