@import "@/assets/styles/global/lib.scss";



























































































































































































































































































































































































































































































































































.rs-solution {
  .solution-banner {
    .flex-box {
      @media screen and (min-width: $breakpoint-lg) {
        .flex-item.two {
          display: unset;
        }
      }
    }
  }
  .solution-market-growth {
    min-height: size(285);
    .image-item {
      max-width: size(1000);
      margin: auto;
      margin-top: size(40);
      img {
        height: auto;
      }
    }
  }
  .markdown.prediction-mesg {
    span.p {
      margin: auto;
      strong {
        color: #54a757;
      }
    }
  }
  .rs-button {
    .link {
      padding-right: size(16);
      padding-left: size(16);
      @media screen and (min-width: $breakpoint-md) {
        padding-right: size(24);
        padding-left: size(24);
      }
    }
  }
}
.flex-box {
  display: flex;
  flex-wrap: wrap;
  &.space-between {
    justify-content: space-between;
  }
  &.align-center {
    align-items: center;
  }
}
