@import "@/assets/styles/global/lib.scss";














































































































































































































































































































































































































































































































































































.rs-coverage {
  .coverage-content {
    .markdown {
      blockquote {
        span {
          font-size: size(14) !important;
          line-height: size(22) !important;
          color: var(--rs-gray-color) !important;
        }
      }
    }
  }
}
