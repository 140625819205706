@import "@/assets/styles/global/lib.scss";















































































.contact-form {
  margin-top: size(150);
  margin-bottom: size(100);
  background: none;
  font-family: var(--rz-hero-font);
  .console-btn {
    font-size: size(15);
    line-height: size(21);
    width: 100%;
    background: var(--rs-black-color);
    svg {
      width: size(8);
      height: size(8);
      fill: transparent;
      display: inline-block;
    }
  }
  .hero-banner {
    .hero-banner--title {
      margin-top: 0;
      margin-bottom: size(10);
    }
    .hero-banner--desc {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .success-field {
    padding-top: size(80);
    p {
      font-size: size(18);
      line-height: size(24);
    }
  }
  .form-group {
    margin-bottom: size(20);
    &.error-field {
      textarea {
        border-color: var(--onboard-error-color);
      }
    }
    &.button-wrapper {
      margin-bottom: 0;
    }
    label {
      text-transform: uppercase;
      color: var(--rs-black-color);
      font-size: size(13);
      line-height: size(18);
      span {
        color: grey;
        padding-left: size(3);
      }
    }
  }
  .flex-col-right {
    box-shadow: 0 size(4) size(20) rgba(0, 0, 0, 0.08);
    padding: size(40);
    border-radius: size(6);
    min-height: size(570);
    background: var(--rs-white-color);
    border: size(1) solid rgba(0, 0, 0, 0.08);
    @media screen and (min-width: $breakpoint-md) {
      min-width: size(300);
      max-width: size(300);
    }
    @media screen and (min-width: $breakpoint-lg) {
      min-width: size(450);
      max-width: size(450);
    }
    textarea {
      padding: size(10);
      width: 100%;
      height: size(120);
      box-sizing: border-box;
      font-size: size(16);
      line-height: size(19);
      border: 0;
      border: size(2) solid #E4E8ED;
      resize: none;
      color: var(--ca-title-color);
      font-family: var(--regular-font);
      border-radius: size(3);
      background-color: var(--on-boarding-primary-backgroundColor);
      &:focus {
        outline: none;
        border: size(2) solid var(--rz-link-color);
      }
    }
  }
}
.check-box-group {
  .link-item {
    display: inline-block;
  }
}

.image-area {
  .show-container {
    img {
      max-height: size(435);
    }
  }
}
.form-group {
  display: flex;
  flex-flow: column;
}
