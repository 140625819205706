@import "@/assets/styles/global/lib.scss";






























































































































































































































img {
  max-width: 100%;
}
.rs-container {
  max-width: size(780);
  margin: 0 auto;
  padding-top: size(25);
  padding-bottom: size(60);
  @media screen and (min-width: $breakpoint-md) {
    padding-bottom: size(35);
  }
}
.expand {
  width: initial;
}
.flex-item-wrapper {
  border-bottom: size(1) solid #C4C4C4;
  padding: 0;
  .flex-item-header {
    padding-bottom: size(30);
    padding-top: size(30);
    cursor: pointer;
    padding-top: size(25);
    padding-bottom: size(25);
  }
  .rs-flex-box {
    transition: height .6s cubic-bezier(.645,.045,.355,1), opacity .3s cubic-bezier(.645,.045,.355,1),
     min-height .6s cubic-bezier(.645,.045,.355,1);
    opacity: 1;
    height: 0;
    min-height: 0;
    overflow: hidden;
    &.hide {
      opacity: 0;
    }
  }
  .expand {
    &.hide {
      &:after {
        transform:rotate(90deg);
      }
      &:before {
        transform: initial;
      }
    }
  }
}
.inner-box {
  flex: 0 0 100%;
}
.expand {
  cursor: pointer;
  position:relative;
  padding: 0 size(10);
  width: size(16);
  height: size(16);
  background-color: transparent;
  border: 0;
  display: inline-block;
  &:before, &:after {
    content: "";
    position: absolute;
    background-color: var(--rs-black-color);
    transition: transform 300ms cubic-bezier(.694,0,.335,1);
    width: size(2);
    height: size(16);
    top: 0;
  }
  &:after {
    transform: rotate(270deg);
  }
  &:before {
    transform: rotate(90deg);
  }
}
.rs-fantasy-points {
  --resource-links-bg: #F5F5F5;
  --desc-font-color: #525252;
  --cards-border-color: #DADADA;

  font-family: var(--rz-hero-font);
  .flex-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tab-wrapper {
    max-width: size(540);
    margin: size(30) auto 0;
    text-align: center;
    span {
      display: inline-block;
      margin: 0 size(10);
      padding: size(10) 0;
      @media screen and (min-width: $breakpoint-md) {
        padding: size(10);
      }
      cursor: pointer;
      &.active {
        border-bottom: solid size(3) var(--rz-link-color);
      }
    }
  }
  .pricing-resource {
    color: var(--ca-title-color);
    position: relative;
    .title {
      font-size: var(--rs-title-level2-font-size);
      line-height: var(--rs-title-level2-line-height);
      font-weight: bold;
      text-transform: capitalize;
      display: inline-block;
      color: var(--rs-black-color);
      padding: 0 size(30) 0 0;
      cursor: pointer;
      max-width: size(210);
      @media screen and (min-width: #{size(376)}) {
        max-width: size(300);
        font-size: size(22);
      }
      @media screen and (min-width: #{size(420)}) {
        max-width: unset;
      }
      @media screen and (min-width: $breakpoint-md) {
        display: inline-block;
      }
    }
    .text {
      padding-top: size(10);
    }
    .table-view {
      padding-bottom: size(15);
      margin: size(10) auto;
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(35);
        &.no-pad {
          padding-bottom: 0;
        }
      }
      &__header, &__row {
        padding: size(15) 0;
        margin-bottom: size(20);
        @media screen and (min-width: $breakpoint-md) {
          padding: size(24) size(16) size(24) 0;
          margin-bottom: size(25);
        }
        div {
          &:nth-child(3),  &:nth-child(4), &:nth-child(5) {
            display: inline-block;
            width: 33%;
            text-align: center;
            padding-top: 1.25rem;
            @media screen and (min-width: $breakpoint-md) {
              text-align: left;
            }
          }
          .sub-text {
            display: block;
            color: var(--rs-gray-color);
            font-size: size(14);
          }
        }
        .label {
          font-size: var(--rs-sm-label-font-size);
          line-height: var(--rs-sm-label-line-height);
          font-weight: normal;
        }
        @media screen and (min-width: $breakpoint-md) {
          border-bottom: size(1) solid var(--cards-border-color);
          padding: 0;
          display: flex;
          margin-bottom: 0;
          div {
            flex: 1;
            padding: size(15) 0 size(16) size(25);
            &:first-child, &:nth-child(2) {
              flex: 2;
            }
            &:last-child {
              min-width: 14%;
              @media screen and (min-width: $breakpoint-md) {
                min-width: 8%;
              }
            }
            &:nth-child(3),  &:nth-child(4), &:nth-child(5) {
              width: initial;
            }
          }
          .label {
            font-size: var(--rs-title-level5-font-size);
            line-height: var(--rs-title-level5-line-height);
          }
        }
      }
      &__row {
        background: var(--regular-text-inv);
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        border-radius: size(10);
        position: relative;
        background: transparent;
        transition: all .3s ease-in-out;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &:last-child {
          border-bottom: none;
        }
        .green {
          display: inline-block;
          padding: size(4) size(8);
          background: #97E6AB;
          border-radius: size(5);
        }
        .red {
          display: inline-block;
          padding: size(4) size(8);
          background: #FF8282;
          border-radius: size(5);
        }
        div {
          &:first-child {
            min-width: 80%;
            flex: 2;
            @media screen and (min-width: $breakpoint-md) {
              min-width: 88%;
            }
            &:before {
              display: none;
            }
            @media screen and (min-width: $breakpoint-md) {
              padding: size(15) 0 size(16) 0;
            }
          }
          &:last-child {
            min-width: 14%;
            @media screen and (min-width: $breakpoint-md) {
              min-width: 8%;
            }
          }
        }
        box-shadow: none;
        border-radius: 0;
        justify-content: initial;
        border-bottom: size(1) solid var(--cards-border-color);
      }
      div {
        &.hide {
          .table-view__inner {
            display: none;
          }
          .sub-heading {
            .collapse-icon {
              display: none;
            }
            .expand-icon {
              display: block;
            }
          }
        }
        .sub-heading {
          .expand-icon {
            display: none;
          }
          .collapse-icon {
            font-size: size(28);
            line-height: size(13);
          }
        }
      }
      &__header {
        background: var(--regular-text-inv);
        display: table;
        width: 100%;
        section {
          display: flex;
          div {
            flex: 1;
            &:first-child, &:nth-child(2) {
              flex: 2;
            }
            &:first-child {
              min-width: 83%;
              padding-left: 0;
              @media screen and (min-width: $breakpoint-md) {
                min-width: 88%;
              }
            }
            &:last-child {
              min-width: 14%;
              @media screen and (min-width: $breakpoint-md) {
                min-width: 8%;
              }
            }
          }
        }
      }
    }
  }
}
