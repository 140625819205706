@import "@/assets/styles/global/lib.scss";



































.get-started-wrap {
  color: var(--rs-black-color);
  .hero-title {
    padding-bottom: size(30);
    font-weight: bold;
  }
  img {
    max-width: 100%;
  }
  h3 {
    font-size: var(--rs-title-level0-font-size);
    line-height: var(--rs-title-level0-line-height);
    font-weight: bold;
    color: var(--rs-black-color);
  }
  p {
    font-size: var(--rs-para-font-size);
    line-height: var(--rs-para-line-height);
    a {
      color: var(--rz-link-color);
    }
  }
  .rs-flex-box {
    justify-content: space-between;
    &.sub-wrap {
      justify-content: initial;
      flex-wrap: initial;
      align-items: center;
      padding-bottom: size(30);
      h3 {
        flex: 0 0 20%;
      }
    }
  }
  .image-wrap {
    max-width: size(540);
    height: size(400);
    margin: 0 auto;
    transform: translateY(#{size(-30)});
  }
  @media screen and (min-width: $breakpoint-md) {
    h3 {
      padding-right: size(30);
    }
    .flex-item-left {
      flex: 0 0 40%;
    }
    .flex-item-right {
      flex: 0 0 50%;
    }
    .image-wrap {
      padding-left: size(50);
    }
  }
  @media screen and (min-width: $breakpoint-lg) {
    .image-wrap {
      padding-left: 0;
      margin: initial;
    }
  }
  @media screen and (max-width: #{size(900)}) and (min-width: $breakpoint-md) {
    .image-wrap {
      max-width: size(400);
      height: size(340);
    }
    .flex-item-right {
      align-self: center;
    }
  }
}
