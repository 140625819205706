@import "@/assets/styles/global/lib.scss";
.sign-up {
  --promo-mesg-title-font-size: #{size(30)};
  --promo-mesg-title-line-height: #{size(37)};
  --promo-mesg-desc-font-size: #{size(24)};
  --promo-mesg-desc-line-height: #{size(34)};
  --author-desg-font-size: #{size(16)};
  --author-desg-line-height: #{size(19)};
  --author-name-font-size: #{size(24)};
  --author-name-line-height: #{size(29)};
  --sign-up-border-color: #DADADA;
  --sign-up-main-title-font-size: #{size(24)};
  --sign-up-main-title-line-height: #{size(34)};
  --sign-up-name-font-size: #{size(15)};
  --sign-up-name-line-height: #{size(21)};
  --sign-up-label-sm-font-size: #{size(12)};
  --sign-up-label-sm-line-height: #{size(17)};
  --onboard-bg-color: #f5f5fa;
  --sign-up-box-padding-top: auto;

  @media screen and (min-width: $breakpoint-lg) {
    --sign-up-box-padding-top: #{size(50)};
  }

  &.login {
    .hero-banner {
      p {
        display: block;
      }
    }
  }
  &.forgot-password {
    background: transparent;
    .flex-col-right {
      max-width: size(380);
      min-width: size(380);
      min-height: size(350);
      h1 {
        font-size: size(24);
        line-height: size(34);
      }
    }
  }

  .signup-image-block {
    display: none;
    @media screen and (min-width: $breakpoint-md) {
      display: block;
    }
    .ring-img {
      display: inline-block;
      width: size(220);
      height: size(420);
      position: absolute;
      right: size(-60);
      bottom: size(120);
      z-index: 1;
      transition: opacity 0.2s linear, transform 0.4s linear;
      animation: slide 1.2s ease;
      @keyframes slide {
        0% {
          transform: translateX(1000px);
          opacity: 0.2;
        }
        100% {
          transform: translateX(0px);
          opacity: 1;
        }
      }
    }
    .bowler-img {
      display: inline-block;
      width: size(120);
      height: size(154);
      position: absolute;
      right: size(85);
      bottom: size(290);
      z-index: 2;
      transition: opacity 0.4s linear, transform 0.6s linear;
      animation: slide 1.8s ease;
      @keyframes slide {
        0% {
          transform: translateX(1000px);
          opacity: 0.2;
        }
        100% {
          transform: translateX(0px);
          opacity: 1;
        }
      }
    }
    .batsmen-img {
      display: inline-block;
      width: size(140);
      height: size(130);
      position: absolute;
      right: size(20);
      bottom: size(456);
      z-index: 3;
      transition: opacity 0.6s linear, transform 0.8s linear;
      animation: slide 2.2s ease;
      @keyframes slide {
        0% {
          transform: translateX(1000px);
          opacity: 0.2;
        }
        100% {
          transform: translateX(0px);
          opacity: 1;
        }
      }
    }
  }
  .hero-banner {
    text-align: left;
    min-height: size(500);
    .logo {
      display: inline-block;
      width: size(108);
      height: size(50);
    }
    &--title {
      font-size: size(32);
      line-height: size(39);
      color: var(--regular-text-inv);
      margin-bottom: size(24);
      margin-top: size(50);
      font-weight: bold;
    }
    svg{
      display: inline-block;
      width: size(16);
      height: size(16);
      margin-top: size(4);
    }
    .content-wrapper {
      display: inline-block;
      vertical-align: top;
      margin-left: size(18);
      width: 90%;
    }
    h2{
      font-size: size(20);
      line-height: size(24);
      font-weight: 600;
      color: var(--regular-text-inv);
      display: inline-block;
      padding-bottom: size(8);
      padding-right: 0;
    }
    p {
      font-size: size(16);
      line-height: size(19);
      color: var(--regular-text-inv);
      display: inline-block;
      padding-bottom: size(24);
    }
  }

  .signup-text {
    font-size: var(--sign-up-main-title-font-size);
    line-height: var(--sign-up-main-title-line-height);
    font-weight: bold;
    color: var(--ca-title-color);
    margin-bottom: size(8);
  }
  .login-info {
    font-size: var(--sign-up-name-font-size);
    line-height: var(--sign-up-name-line-height);
    padding-bottom: size(30);
    &-link {
      color: var(--rz-link-color);
      font-weight: normal;
    }
  }
  .cancel-btn {
    display: inline-block;
    margin-top: size(50);
    cursor: pointer;
    text-decoration: underline;
    font-size: size(14);
    line-height: size(17);
  }

  .cancel-btn-ants {
    color: var(--rz-ants-primary-button-bg);
  }
   
  .form-group {
    transition: all 0.3s ease-in;
    &:empty {
      margin: 0;
    }
    margin-bottom: size(20);
    &.password-div{
      margin-bottom: size(20);
    }
    p {
      margin-top: size(5);
    }
    .error-msg.hide {
      transform: scale(0);
    }
    &.error-field {
      input {
        outline: none;
        box-shadow: none;
        border-color: var(--onboard-error-color);
      }
      textarea {
        outline: none;
        box-shadow: none;
        border: size(2) solid var(--onboard-error-color);
      }
      .error-msg {
        transform: scale(1);
        color: var(--onboard-error-color);
        font-size: size(12);
        line-height: size(17);
      }
    }
    input {
      padding: size(10);
      width: 100%;
      box-sizing: border-box;
      font-size: size(15);
      line-height: size(17);
      border: 0;
      border: size(1) solid var(--sign-up-border-color);
      color: var(--ca-title-color);
      border-radius: size(3);
      background-color: var(--rs-white-color);
      &:focus {
        outline: none;
        border: size(2) solid var(--rz-link-color);
      }
    }
    .field-label {
      display: block;
      font-size: size(12);
      line-height: size(17);
      color: #777777;
      padding-bottom: size(6);
      font-weight: 500;
      &.password {
        display: inline-block;
      }
    }
    .forget-pass-btn {
      float: right;
      font-size: size(12);
      line-height: size(17);
      color: var(--rz-link-color);;
      padding-bottom: size(6);
      font-weight: normal;
      &:focus {
        text-decoration: underline;
        outline: none;
      }
    }
  }
  
  margin: auto;
  .flex {
    display: block;
    font-family: var(--rz-hero-font);
    &-col {
      &-right {
        box-sizing: border-box;
        padding: size(50) size(40) size(100);
        background: var(--onboard-bg-color);
        width: 100%;
        max-width: 100%;
        @media screen and (min-width: $breakpoint-md) {
          padding: size(120) size(200) size(150);
        }
        .form-wrapper {
          @media screen and (min-width: $breakpoint-lg) {
            max-width: size(380);
            padding: 0;
            margin: 0 auto;
          }
        }
        .register-form {
          padding-bottom: size(50);
          @media screen and (min-width: $breakpoint-md) {
            padding-bottom: 0;
          }
          &>.error-msg {
            color: var(--onboard-error-color);
            padding-bottom: size(10);
          }
        }
        a { 
          &.console-btn {
            padding: size(13) 0;
          }
        }
        .footer {
          font-size: size(14);
          line-height: size(17);
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          display: block;
          padding: 0;
          margin: auto;
          @media screen and (min-width: $breakpoint-md) {
            display: flex;
            justify-content: space-between;
            padding: size(11) var(--onboard-padding) size(11) var(--onboard-padding);
          }
          & .footer-flex-col {
            &-left {
              padding-left: 0;
            }
          }
          & .footer-flex-col {
            &-right {
              margin-top: size(15);
              @media screen and (min-width: $breakpoint-md) {
                margin-top: 0;
              }
              .link-item {
                margin-right: size(16);
                margin-bottom: size(8);
                @media screen and (min-width: $breakpoint-md) {
                  margin-left: size(16);
                  margin-bottom: 0;
                  margin-right: 0;
                }
                color: var(--rs-black-color);
                display: inline-block;
              }
            }
          }
        }
        
        .console-btn {
          // margin-bottom: size(36);
          outline: none;
          font-weight: bold;
          &:hover {
              color: var(--regular-text-inv);
          }
        }
        .success-field {
          text-align: center;
          &.email {
            p {
              padding-top: size(20);
              padding-bottom: 0;
            }
          }
          h1 {
            padding: size(10) 0;
            font-weight: bold;
            font-size: size(42);
            line-height: size(56);
            color: var(--ca-title-color);
          }
          p {
            padding-bottom: size(30);
            font-weight: 600;
            font-size: size(16);
            line-height: size(19);
            color: var(--ca-title-color);
          }
        }

        .text-or {
          font-size: var(--sign-up-name-font-size);
          line-height: var(--sign-up-name-line-height);
          margin-bottom: size(16);
          text-align: center;
        }
        .checkbox-content {
          display: inline-block;
          vertical-align: middle;
          margin-top: size(20);
          padding-left: size(10);
          font-size: size(12);
          line-height: size(15);
          cursor: pointer;
          color: var(--ca-title-color);
        }
        .check-box {
          display: inline-block;
          vertical-align: top;
          width: size(15);
          height: size(15);
          margin-top: size(20);
        }
        .signup-btn {
          font-size: var(--sign-up-name-font-size);
          line-height: var(--sign-up-name-line-height);
          border-radius: size(6);
          border: size(1) solid var(--sign-up-border-color);
          padding: size(10) size(16);
          cursor: pointer;
          outline: none;
          vertical-align: middle;
          &:hover {
            animation: jump-up-shadow 350ms ease-out;
          }
        }
        .social-signup-group {
          text-align: center;
          img {
            vertical-align: middle;
            padding-right: size(8);
          }
          .signup-btn:first-of-type {
            margin-right: size(26);
          }
        }
        .warning {
          padding: size(15) 0;
          &-info {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-in;
            p {
              display: inline-block;
              padding-left: size(5);
              color: var(--onboard-error-color);
            }
          }
          &:last-child {
            padding-bottom: size(21);
          }
          &.check-box {
            padding-bottom: size(40);
          }
        }
        .check-box {
          &-label {
            font-size: var(--author-desg-font-size);
            line-height: var(--author-desg-line-height);
            color: var(--rs-black-color);
            vertical-align: middle;
          }
          &-link {
            font-size: var(--author-desg-font-size);
            line-height: var(--author-desg-line-height);
            color: var(--rz-link-color);
            font-weight: normal;
            outline: none;
          }
          &-group {
            display: inline-block;
            vertical-align: top;
            position: relative;
            font-size: var(--author-desg-font-size);
            line-height: var(--author-desg-line-height);
            color: var(--rs-black-color);
            outline: none;
            cursor: pointer;
            input[type='checkbox'] {
              visibility: hidden;
            }
            &.terms-and-conditions {
              font-weight: normal;
              margin-bottom: size(20);
              font-size: var(--sign-up-label-sm-font-size);
              color: var(--rs-black-color);
              span {
                font-weight: normal;
              }
              a {
                text-decoration: underline;
              }
            }
          }
        }

        .tickmark {
          position: absolute;
          top: size(18);
          left: 0;
          border-radius: size(4);
          border: size(1) solid var(--rz-link-color);
          width: size(15);
          height: size(15);
          cursor: pointer;
        }

        .check-box-group input:checked ~ .tickmark {
          background-color: var(--rz-link-color);
          padding: size(1);
        }

        .tickmark:after {
          content: '';
          position: absolute;
          display: none;
        }

        .check-box-group input:checked ~ .tickmark:after {
          display: block;
        }

        .check-box-group .tickmark:after {
          left: size(5);
          border-top: solid var(--regular-text-inv);
          border-left: solid var(--regular-text-inv);
          bottom: size(3);
          width: size(5);
          height: size(12);
          -webkit-transform: rotate(200deg);
          -ms-transform: rotate(200deg);
          transform: rotate(220deg);
        }

      }
    }
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    &.warning-icon {
      height: size(12);
      width: size(12);
    }
    &.google-icon,
    &.github-icon {
      height: size(28);
      width: size(28);
      padding-right: size(8);
    }
  }
  .alert-warning {
    padding: size(5) 0;
    p {
      font-size: size(16);
      color: var(--onboard-error-color);
    }
  }
  .image-container, .hide-container {
    max-width: size(335);
  }
  .image-item {
    text-align: left;
  }
}

.ants-sign-up {
  .form-group.error-field {
      .error-msg {
        color: var(--rz-ants-error-color);
    }
    input {
      border-color:  var(--rz-ants-error-color);
    }
  }
 .flex-col-right {
  background: var(--rz-ants-color-primary-bg);
  font-family: var(--rz-ants-font);

  .check-box-group.terms-and-conditions {
      color: var(--rs-white-color);

      a {
        color: var(--rz-ants-color-text1);
      }
  }
 }
 .signup-text {
  color: var(--rs-white-color);
 }
 .login-info {
  color: var(--rz-ants-color-text1);

 }
 .login-info-link {
  color: var(--rz-ants-primary-button-bg);
 }
.ants-form-group {
      label {
        color: var(--rz-ants-color-text1);
      }
      input {
        background: var(--rz-ants-primary-bg);
        padding: size(12) size(25) size(12) size(16);
        margin-bottom: size(8);
        color: var(--rs-white-color);
        border: size(1) solid var(--rz-ants-color-border);

        &:hover{
          border-color: transparent;
          border: size(1) solid var(--rz-ants-color-border);
        }
        &:focus {
          border: size(1) solid var(--rz-ants-color-border);
          outline: none;
        }
      }
    }
}