@import "@/assets/styles/global/lib.scss";


































































































































































.detail-text {
  &.markdown {
    em {
      font-style: italic;
      font-weight: normal;
    }
    strong {
      font-weight: bolder;
      em {
        font-weight: bolder;
      }
    }
    ul {
      list-style-type: disc;
      padding-left: size(30);
    }
  }
}
.quote-wrap {
  .detail-text {
    &.markdown {
      display: inline-block;
      .p, li {
        font-size: var(--rz-para-hero-font-size);
        line-height: var(--rz-para-hero-line-height);
        color: var(--rz-type-color);
        &:last-child {
          padding-bottom: 0;
        }
      }
      .p {
        padding-bottom: size(30);
        font-family: var(--rz-para-font);
      }
    }
  }
}
.tech-process-wrap {
  .detail-text {
    &.markdown {
      display: inline-block;
      .p {
        color: var(--rs-black-color);
        font-size: var(--rs-title-level4-font-size);
        line-height: var(--rs-title-level4-line-height);
        font-family: var(--rz-para-font);
        &:last-child {
          padding-bottom: 0;
        }
      }
      ul {
        li {
          font-size: var(--rs-title-level4-font-size);
          line-height: var(--rs-title-level4-line-height);
          color: var(--rs-black-color);
          padding-top: size(6);
          &:first-child {
            padding-top: 0;
          }
          a {
            text-decoration: underline;
          }
        }
        padding-bottom: size(30);
        &:last-child {
          padding-bottom: 0;
        }
      }
      h3 {
        padding-bottom: size(12);
      }
    }
  }
   &.white {
    .title, .detail-text {
      color: var(--rs-white-color);
      &.markdown {
        .p {
          color: var(--rs-white-color);
        }
      }
    }
  }
}
