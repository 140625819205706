@import "@/assets/styles/global/lib.scss";









































































































































































































































































































































































































































































































































































































































































































































































.package-price {
  &.pricing-page {
    .solution-banner {
      .flex-box {
        .one {
          span {
            display: block;
          }
        }
      }
    }
  }
  .solution-banner {
    background: transparent;
    clip-path: none;
    &.pricing {
        &.package-type {
        min-height: size(370);
        @media screen and (min-width: $breakpoint-md) {
          min-height: size(200);
        }

        @media screen and (min-width: $breakpoint-sm) {
          min-height: size(100);
        }
      }
    }

    &.resource {
      padding-bottom: 0;
    }

    .flex-box {
      justify-content: center;
      .one {
        text-align: center;
         .hero-links {
          display: none;
        }
        span {
          color: #131313;
        }
      }

      .banner-title {
        max-width: 100%;
        color: #131313;
      }
    }
  }

  .package-price-wrapper {
    > div {
      .markdown {
        display: inline-block;
        margin: 0;
        em {
          font-style: italic;
          font-weight: normal;
        }
        strong {
          font-weight: bolder;
          em {
            font-weight: bolder;
          }
        }
        &.list-view {
          padding-top: size(6);
          margin: 0;
          strong {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        ul {
          list-style-type: disc;
          padding-left: size(20);
          li {
            font-size: size(14);
            line-height: size(20);
            padding-top: size(6);
            color: #000000;
            &:first-child {
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  #package-based {
    padding-top: 0;
    .title {
      .p {
        @media screen and (min-width: $breakpoint-lg) {
          font-weight: 800;
          font-size: size(80);
          line-height: size(90);
          padding-bottom: size(20)
        }
        strong {
          color: #4F37C8;
        }
      }
    }
    .package-info {
      padding-top: size(30);
      @media screen and (min-width: $breakpoint-lg) {
        padding-top: size(40);
      }
    }
  }
  .committed-usage {
    .rs-container {
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(100);
      }
    }
  }
  .content-section {
    max-width: size(550);
    padding: size(30) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding-top: size(80);
      padding-bottom: size(40);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding-top: size(120);
      padding-bottom: size(60);
    }
    &:empty {
      padding: 0;
    }
    .markdown {
      .p {
        font-weight: bold;
        font-size: size(20);
        line-height: size(26);
        padding-bottom: size(10);
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(24);
          line-height: size(32);
        }
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(30);
          line-height: size(42);
        }
        a {
          color: var(--hero-text);
        }
        strong {
          font-weight: bold;
        }
      }
    }
    .content {
      .p {
        font-size: size(16);
        line-height: size(24);
        font-weight: normal;
        padding-bottom: 0;
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(18);
          line-height: size(28);
        }
        strong {
          font-weight: bold;
        }
      }
    }
    p {
      font-size: size(16);
      line-height: size(24);
      @media screen and (min-width: $breakpoint-md) {
        font-size: size(18);
        line-height: size(28);
      }
    }
  }
  .package-plans-section {
    padding: size(30) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(60) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(100) 0;
    }

    .section-wrapper {
      .plan-box-wrapper {
        margin-bottom: 0;
      }
    }
  }
}
.package-pricing-container {
  .markdown {
    margin-bottom: 0;
  }
}
.compare-plans {
  margin-top: size(30);
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: $breakpoint-md) {
    margin-top: size(40);
  }
  button {
    font-size: size(15);
    font-weight: 500;
    line-height: size(16);
    text-decoration-line: underline;
    cursor: pointer;
  }
}
.developer-kit {
  .hero-title {
    color: var(--rs-white-color);
    font-size: size(32);
    font-weight: 700;
    line-height: size(40);
    margin-bottom: size(10);

    @media screen and (min-width: $breakpoint-md) {
      font-size: size(42);
      line-height: size(50);
    }
  }
  .content {
    color: var(--rs-white-color);
    font-size: size(16);
    font-weight: 400;
    line-height: size(24);
    margin-bottom: size(35);
  }

  .license-cta {
    a {
      padding: size(12) size(40);
      background: var(--rz-link-color);
      color: var(--rs-white-color);
      border-radius: size(5);
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      font-size: size(15);
      font-weight: 700;

      &:hover {
        color: var(--rs-white-color);
      }
    }
  }

  .hide-on-desktop {
    display: block;
    @media screen and (min-width: $breakpoint-md) {
      display: none;
    }
  }

  .right-wrapper {
    display: none;
    @media screen and (min-width: $breakpoint-md) {
      display: flex;
    }
  }

  .list {
    margin-bottom: 0;
    ul {
      list-style-type: none;
      padding-left: 0;
      @media screen and (min-width: $breakpoint-md) {
        padding-top: size(30);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-top: size(66);
        columns: 2;
      }
      li {
        display: flex;
        align-items: center;
        color: var(--rs-white-color);
        font-size: size(14);
        font-weight: 400;
        line-height: size(21);
        margin-bottom: size(12);
        padding: 0;
        @media screen and (min-width: $breakpoint-lg) {
          margin-bottom: size(28);
        }
        &:before {
          display: inline-block;
          padding-right: size(10);
          line-height: 0;
        }
        &:nth-child(1) {
          &:before {
            content: url('~@/assets/dev-feat-1.svg');
          }
        }
        &:nth-child(2) {
          &:before {
            content: url('~@/assets/dev-feat-2.svg');
          }
        }
        &:nth-child(3) {
          &:before {
            content: url('~@/assets/dev-feat-3.svg');
          }
          @media screen and (min-width: $breakpoint-lg) {
            margin-bottom: 0;
          }
        }
        &:nth-child(4) {
          &:before {
            content: url('~@/assets/dev-feat-4.svg');
          }
        }
        &:nth-child(5) {
          &:before {
            content: url('~@/assets/dev-feat-5.svg');
          }
        }
        &:nth-child(6) {
          &:before {
            content: url('~@/assets/dev-feat-6.svg');
          }
          @media screen and (min-width: $breakpoint-lg) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
