@import "@/assets/styles/global/lib.scss";
















































































































































































































































.rs-article-list {
  --rs-article-border-radius: #{size(6)};
  .article-list-two-col {
    .flex-child.two .image-item {
      img {
        height: auto;
        max-height: size(600);
        border-radius: var(--rs-article-border-radius);
      }
    }
  }
}
