@import "@/assets/styles/global/lib.scss";

























































.article-list-two-col {
  --rs-article-detail-view-blue-color: #8571eb;
  color: var(--rs-black-color);
  .max-width-1080 {
    max-width: size(1080);
  }
  .flex-parent {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding-top: size(30);
    border-bottom: size(1) dashed var(--rs-gray-color);
    @media screen and (min-width: $breakpoint-md) {
      flex-direction: row;
    }
    .flex-child {
      &.one {
        width: 100%;
        @media screen and (min-width: $breakpoint-md) {
          padding-right: size(15);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: 50%;
        }
        .hash-tag {
          font-size: var(--rs-title-level5-font-size);
          line-height: var(--rs-title-level5-line-height);
          color: var(--rs-article-detail-view-blue-color);
          padding-bottom: size(10);
          font-weight: bold;
        }
        .title {
          font-size: var(--rs-title-level3-font-size);
          line-height: var(--rs-title-level3-line-height);
          padding-bottom: size(18);
          font-weight: bold;
          color: var(--rs-black-color);
          @media screen and (min-width: $breakpoint-lg) {
            font-size: var(--rs-title-level2-font-size);
            line-height: var(--rs-title-level2-line-height);
          }
        }
        .desc {
          font-size: var(--rs-title-level5-font-size);
          line-height: var(--rs-title-level5-line-height);
          @media screen and (min-width: $breakpoint-lg) {
            font-size: var(--rs-title-level4-font-size);
            line-height: var(--rs-title-level4-line-height);
          }
          padding-bottom: size(18);
        }
        .article-date {
          padding-bottom: size(10);
          color: var(--rs-gray-color);
          font-size: var(--rs-sm-label-font-size);
          line-height: var(--rs-sm-label-line-height);
        }
        a:hover {
          .title {
            color: var(--rs-cta-blue-color);
          }
        }
      }
      &.two {
        width: 100%;
        margin-left: size(0);
        margin-bottom: size(10);
        @media screen and (min-width: $breakpoint-lg) {
          margin-left: size(50);
          margin-bottom: size(0);
          width: 45%;
        }
        img {
          width: 100%;
          height: auto;
          max-width: size(300);
          @media screen and (min-width: $breakpoint-md) {
            max-width: size(350);
          }
          @media screen and (min-width: $breakpoint-lg) {
            max-width: size(544);
          }
        }
      }
    }
  }
  .author-container {
    display: flex;
    flex-direction: column;
    margin-bottom: size(30);
    @media screen and (min-width: $breakpoint-md) {
      flex-direction: row;
    }
    .author-name {
      padding-left: size(5);
      font-size: var(--rs-sm-label-font-size);
      line-height: var(--rs-sm-label-line-height);
      font-weight: 600;
      padding-bottom: 0;
    }
    .author {
      display: flex;
      align-items: center;
      &:not(:first-child) {
        margin-left: size(0);
        @media screen and (min-width: $breakpoint-md) {
          margin-left: size(15);
        }
      }
      &:not(:last-child) {
        padding-bottom: size(5);
         @media screen and (min-width: $breakpoint-md) {
          padding-bottom: size(0);
        }
      }
    }
    .author-pic {
      width: size(35.2);
      height: size(35.2);
      border-radius: 50%;
      display: block;
      &-wrap {
        display: inline-block;
      }
    }
  }
}
