@import "@/assets/styles/global/lib.scss";





































































































































































































































































































































































































.rs-get-started {
  .banner {
    .flex-box {
      .flex-item.two {
        display: none;
      }
      @media screen and (min-width: $breakpoint-lg) {
        .flex-item.two {
          display: unset;
        }
      }
    }
  }
  .step-container-two {
    .get-started-step.two .content {
      margin-left: unset;
      margin-top: size(75);
      @media screen and (min-width: $breakpoint-lg) {
        margin-left: auto;
        margin-top: unset;
      }
    }
    .get-started-step .image-sec {
        height: size(300);
        @media screen and (min-width: $breakpoint-md) {
          height: size(480);
        }
      }
  }
  .step-container-one {
    .get-started-step {
      padding-top: size(25);
      padding-bottom: size(0);
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(35);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-bottom: size(25);
        padding-top: unset;
      }
      .image-sec {
        height: size(300);
        @media screen and (min-width: $breakpoint-md) {
          height: size(480);
        }
      }
    }
  }
  .step-container-three {
    margin-left: auto;
    .pad-200 {
      padding-top: size(40);
    }
    .image-sec {
      height: size(300);
      @media screen and (min-width: $breakpoint-md) {
        height: size(480);
      }
    }
    .content {
      padding-bottom: size(25);
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(0);
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      padding-bottom: unset;
      .image-wrapper {
        margin-left: auto;
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      margin-left: unset;
      .pad-200 {
        padding-top: unset;
      }
    }
    .get-started-step.two-column {
      .content {
        padding-top: size(0);
        @media screen and (min-width: $breakpoint-lg) {
          padding-top: size(40);
        }
      }
    }
  }
  .quote-wrap.tech-process-wrap .detail-text {
    max-width: unset;
  }
  .flex-box {
    display: flex;
    flex-wrap: wrap;
    &.space-between {
      justify-content: space-between;
    }
    &.align-center {
      align-items: center;
    }
    }

    .banner {
    .image-wrap {
      height: size(380);
      max-width: size(480);
    }
  }
}
