@import "@/assets/styles/global/lib.scss";
















































































































































.home-footer-block {
  --footer-block-top-margin: #{size(80)};
  --support-title-font-size: #{size(30)};
  --support-title-line-height: #{size(32)};
  --footer-links-title-pad-bottom: #{size(4)};

  --support-section-pad-top: #{size(58)};
  --support-section-pad-bottom: #{size(40)};

  --support-para-font-size: #{size(16)};
  --support-para-line-height: #{size(24)};
  --currency-box-border-color: #C4C4C4;

  --footer-title-bg1: #53FF83;
  --footer-title-bg2: #FFE817;

  --footer-company-name-font-size: #{size(20)};
  --footer-company-name-line-height: #{size(19)};
  &.have-submenu {
    --footer-block-top-margin: 0;
  }

  @media screen and (min-width: $breakpoint-md) {
    --footer-block-top-margin: #{size(140)};
    --support-title-font-size: #{size(42)};
    --support-title-line-height: #{size(56)};
    --footer-links-title-pad-bottom: #{size(8)};

    --support-section-pad-top: #{size(100)};
    --support-section-pad-bottom: #{size(50)};

    --support-para-font-size: #{size(20)};
    --support-para-line-height: #{size(28)};

    --footer-company-name-font-size: #{size(34)};
    --footer-company-name-line-height: #{size(24)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --footer-block-top-margin: #{size(200)};
    --footer-links-title-pad-bottom: #{size(12)};
  }

  background-color: var(--ca-title-color);
  color: var(--regular-text-inv);
  margin-top: var(--footer-block-top-margin);

  &.rz-footer {
    background-color: #FBFBFB;
    &.rs-footer {
      .footer {
        .inner-block {
          .company {
            flex: 0 0 15%;
          }
        }
        @media screen and (min-width: $breakpoint-md) {
          .rs-flex-box {
            padding-left: size(30);
          }
          .inner-block {
            .links {
              max-width: size(200);
            }
          }
        }
        @media screen and (min-width: $breakpoint-lg) {
          .rs-flex-box {
            padding-left: 0;
          }
          .inner-block {
            .links {
              max-width: initial;
            }
          }
        }
      }
    }
    .footer {
      position: relative;
      padding-bottom: size(20);
      .image-wrap {
        &.decor {
          position: absolute;
          right: size(100);
          top: size(-168);
        }
        img {
          max-width: 100%;
        }
      }
      .footer-container {
        border: none;
      }
      .inner-block {
        .company {
          flex: 0 0 20%;
        }
        .links {
          margin-top: size(30);
          h3 {
            font-size: var(--rz-title-small-font-size);
            line-height: var(--rz-title-small-line-height);
            color: var(--rz-title-color);
            margin-bottom: size(10);
          }
          .links-section {
            &:first-child {
              margin-right: 5rem;
              margin-left: 0;
              @media screen and (min-width: $breakpoint-md) {
                margin-right: 3rem;
              }
              @media screen and (min-width: $breakpoint-lg) {
                margin-right: 5rem;
              }
            }
            li {
              padding: 0;
            }
          }
          @media screen and (min-width: $breakpoint-md) {
            margin-bottom: size(30);
            margin-top: 0;
          }
        }
        .tools-section {
          padding-top: size(20);
        }
      }
      .toc-links {
        margin-top: size(90);
        margin-bottom: size(120);
        @media screen and (min-width: $breakpoint-md) {
          margin-bottom: size(80);
        }
        @media screen and (min-width: $breakpoint-lg) {
          margin-bottom: size(60);
        }
        .inner-block {
          display: block;
          text-align: center;
        }
        .company {
          p {
            font-size: size(14);
            line-height: size(18);
            color: var(--rz-title-color);
            font-family: var(--rz-hero-font);
            max-width: initial;
            padding-top: size(20);
            border-top: size(1) dashed var(--rz-cta-color);
            @media screen and (min-width: $breakpoint-md) {
              font-size: var(--rz-para-reg-font-size);
              line-height: var(--rz-para-reg-line-height);
            }
          }
        }
      }
    }
  }

  .home-support-section {
    padding: var(--support-section-pad-top) 0 var(--support-section-pad-bottom) 0;
    .support-content {
      max-width: size(860);
      margin: 0 auto;
      h2 {
        font-family: var(--hero-font);
        font-weight: bold;

        font-size: var(--support-title-font-size);
        line-height: var(--support-title-line-height);
        color: var(--hero-text);
        margin-bottom: size(20);
        text-align: center;
        max-width: none;
        padding-right: 0;
        @include titile_gradient(var(--footer-title-bg1), var(--footer-title-bg2));
      }
      p {
        font-size: var(--support-para-font-size);
        line-height: var(--support-para-line-height);
        text-align: center;
        max-width: none;
        padding: 0 15px;
        br {
          display: none;
          @media screen and (min-width: $breakpoint-lg) {
            display: block;
          }
        }
      }
      .contact-block {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .item {
          .link {
            a {
              font-size: size(22);
              line-height: size(29);
              font-family: var(--regular-font);
              color: var(--regular-text-inv);
              font-weight: 600;
              margin: size(30) 0 size(0);
              display: block;
              text-align: center;
              @media screen and (min-width: $breakpoint-md) {
                margin: size(40) 0 size(9);
                font-size: size(24);
              }
              @media screen and (min-width: $breakpoint-lg) {
                margin: size(50) 0 size(9);
                font-size: size(26);
                line-height: size(32);
              }
              &:hover {
                color: #FFBE16;
              }
            }
          }
          .link-text {
            text-align: center;
            font-size: size(16);
            line-height: size(38);
            img {
              vertical-align: middle;
              margin-right: size(8);
              height: size(10);
              transform: translateY(-2px);
              @media screen and (min-width: $breakpoint-md) {
                height: size(12);
              }
            }
          }
          &:first-child {
            .link {
              a {
                color: #FFBE16;
              }
            }
          }
        }
      }
    }
  }
  .flex-box {
    display: flex;
    .logo-img {
      padding-right: size(20);
    }
  }
  @media screen and (min-width: $breakpoint-md) {
    .home-support-section {
      .support-content {
        .contact-block {
          flex-direction: row;
          justify-content: center;
          padding-left: size(30);
          padding-right: size(30);
          .item {
            padding: 0 size(40);
          }
        }
      }
    }
  }
  @media screen and (min-width: $breakpoint-lg) {
    .home-support-section {
      .support-content {
        .contact-block {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  .footer {
    padding-bottom: size(30);
    .footer-container {
      padding: size(50) size(15) 0;
      border-top: 1px solid var(--gray-line);
      max-width: size(1180);
      margin: 0 auto;
      position: relative;
      @media screen and (min-width: $breakpoint-md) {
        padding: size(50) size(30) 0;
      }
    }
    .inner-block {
      display: block;
      @media screen and (min-width: $breakpoint-md) {
        flex-direction: row;
        display: flex;
      }
      .links {
        flex-direction: column;
        display: flex;
        margin-top: size(50);
        @media screen and (min-width: $breakpoint-md) {
          margin-top: size(95);
        }
        // @media screen and (min-width: $breakpoint-lg) {
        //   margin-top: 0;
        // }
        h3 {
          font-size: size(20);
          line-height: size(24);
          font-weight: 500;
          margin-bottom: size(20);
        }
        .links-section-wrapper {
          display: flex;
          flex-direction: column;
          @media screen and (min-width: $breakpoint-md) {
            flex-direction: initial;
          }
        }
        .links-section {
          &:first-child {
            order: 2;
            @media screen and (min-width: $breakpoint-md) {
              margin-left: size(80);
            }
          }
          li {
            font-size: size(16);
            line-height: size(36);
            padding: 0;
            @media screen and (min-width: $breakpoint-md) {
              padding: size(5) 0;
            }
            .link-plain {
              color: #fff;
              &:hover {
                opacity: 0.8;
              }
            }
            @media screen and (min-width: $breakpoint-md) {
              line-height: size(19);
            }
          }
        }
      }
      .company {
        @media screen and (min-width: $breakpoint-md) {
          flex: 0 0 50%;
        }
        @media screen and (min-width: $breakpoint-lg) {
          flex: 0 0 60%;
        }
        img {
          width: size(120);
          vertical-align: middle;
        }
        .company-content {
          display: inline-block;
          vertical-align: middle;
          h2 {
            font-size: var(--footer-company-name-font-size);
            line-height: var(--footer-company-name-line-height);
            color: #fff;
          }
          p {
            font-size: size(16);
            margin-top: size(10);
            @media screen and (min-width: $breakpoint-md) {
              font-size: size(20);
              margin-top: size(12);
            }
            a {
              color: #fff;
              text-decoration: underline;
            }
          }
        }
        .address {
          margin-top: size(40);
          h3, p {
            font-size: size(16);
            line-height: size(19);
          }
          h3 {
            font-weight: bold;
            margin-bottom: size(10);
          }
        }
      }
    }
    .toc-links {
      margin-top: size(30);
      @media screen and (min-width: $breakpoint-md) {
        margin-top: size(90);
      }
      .company {
        flex: auto;
        @media screen and (min-width: $breakpoint-lg) {
          flex: 0 0 55%;
        }
        .social {
          margin-bottom: size(30);
          position: absolute;
          right: 0;
          bottom: size(50);
          @media screen and (min-width: $breakpoint-md) {
            position: initial;
          }
          .icon {
            display: inline-block;
            width: size(15);
            padding-right: size(15);
            a {
              display: block;
              img {
                width: auto;
                height: size(17);
              }
            }
          }
        }
        p {
          font-size: size(12);
          line-height: size(15);
          @media screen and (min-width: $breakpoint-md) {
            font-size: size(14);
            line-height: size(17);
          }
        }
      }
      .links {
        justify-content: end;
        margin-top: size(10);
        @media screen and (min-width: $breakpoint-md) {
          margin-top: size(50);
        }
        .links-section {
          &:first-child {
            margin-left: 0;
          }
          li {
            display: inline-block;
            padding: 0 size(10) 0 0;
            @media screen and (min-width: $breakpoint-md) {
              padding: 0 size(10);
            }
            font-size: size(12);
            line-height: size(15);
            @media screen and (min-width: $breakpoint-md) {
              font-size: size(14);
              line-height: size(17);
            }
          }
        }
      }
    }
  }
  .currency-block-outer {
    .currency-block {
      border: 1px solid var(--currency-box-border-color) !important;
      box-shadow: none !important;
      padding: size(8) size(18) !important;
      p {
        color: var(--rs-black-color);
        font-size: size(12);
        line-height: size(17);
        font-weight: 500;
        padding-top: size(2.5);
        padding-bottom: size(2.5);
      }
    }
    .dropdown-list {
      padding: 0 size(18) 0 !important;
      border-left: size(1) solid var(--currency-box-border-color);
      border-right: size(1) solid var(--currency-box-border-color);
      right: size(-1) !important;
      left: size(-1) !important;
      &.add-height {
        border-bottom: size(1) solid var(--currency-box-border-color) !important;
      }
    }
  }
}
.rsArticleDetailView, .rsArticleList, .rsArticleArchives {
  .home-footer-block {
    display: none;
  }
}
