@import "@/assets/styles/global/lib.scss";

































 .customers-wrap {
  padding: size(80) 0 size(50);
  img {
    max-width: 100%;
  }
  .image {
    max-width: size(300);
    height: size(160);
  }
  .flex-box {
    display: flex;
    flex-wrap: wrap;
    &.no-wrap {
      align-items: initial;
      flex-wrap: initial;
    }
    .flex-item {
      align-self: flex-start;
      padding-bottom: size(30);
      @media (min-width: $breakpoint-md) {
        padding-bottom: size(40);
      }
      @media (min-width: $breakpoint-lg) {
        padding-bottom: size(60);
      }
    }
    .image-wrap {
      padding-right: size(10);
      flex: 0 0 10%;
    }
  }
  &.rs-page {
    padding-bottom: 0;
  }
  @media (min-width: $breakpoint-md) {
    .flex-box {
      .flex-item {
        flex: 0 0 45%;
        max-width: size(400);
        &:nth-child(2), &:nth-child(4) {
          padding-left: size(70);
        }
      }
    }
    &.rs-page {
       .flex-box {
        .flex-item {
          flex: 0 0 47%;
          &:nth-child(2) {
            padding-top: size(150);
          }
          &:nth-child(3) {
            position: relative;
            top: size(-107);
          }
          &:nth-child(2), &:nth-child(4) {
            padding-left: size(28);
          }
        }
      }
    }
  }
  @media screen and (min-width: $breakpoint-lg) {
    padding: size(80) 0 0 0;
    .flex-box {
      .flex-item {
        max-width: initial;
        padding-bottom: size(100);
      }
      &.no-wrap {
        padding-bottom: size(50);
      }
      .image-wrap {
        padding-right: size(80);
      }
    }
     &.rs-page {
        .flex-box {
          .flex-item {
            align-self: flex-start;
            padding-bottom: size(20);
          }
        }
     }
  }
  &.rs-page {
    .section-title {
      font-size: var(--rs-title-level4-font-size);
      line-height: var(--rs-title-level4-line-height);
      font-weight: bold;
      color: var(--rs-black-color);
      font-family: var(--rz-hero-font);
      letter-spacing: 0.1em;
      text-transform: uppercase;
      padding-bottom: size(10);
    }
  }
}
.customer-selector-wrap {
  .title {
    font-size: var(--rz-title-level0-font-size);
    line-height: var(--rz-title-level0-line-height);
    font-weight: bold;
    color: var(--rz-title-color);
    padding-bottom: size(10);
    font-family: var(--rz-hero-font);
  }
  .detail-text {
    font-size: var(--rz-para-hero-font-size);
    line-height: var(--rz-para-hero-line-height);
    color: var(--rz-type-color);
    padding-bottom: size(30);
    font-family: var(--rz-hero-font);
  }
  img {
    max-width: 100%;
  }
  &.rs-page {
    background: var(--rs-white-color);
    box-shadow: 0 0 size(30) rgba(141, 151, 158, 0.2);
    border-radius: size(6);
    padding: size(28);
    .title {
      font-size: var(--rs-title-level2-font-size);
      line-height: var(--rs-title-level2-line-height);
      color: var(--rs-black-color);
    }
    .detail-text {
      color: var(--rs-black-color);
      font-size: var(--rs-para-font-size);
      line-height: var(--rs-para-line-height);
    }
  }
}
