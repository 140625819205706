@import "@/assets/styles/global/lib.scss";

































































































































































































































































































































































































































































































































































































































































































































































.pricing {
  #package-based {
    padding-top: 0;
    .title {
      .p {
        @media screen and (min-width: $breakpoint-lg) {
          font-weight: 800;
          font-size: size(80);
          line-height: size(90);
          padding-bottom: size(20)
        }
        strong {
          color: #4F37C8;
        }
      }
    }
  }
  .committed-usage {
    .rs-container {
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(100);
      }
    }
  }
  .content-section {
    max-width: size(550);
    padding: size(30) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding-top: size(80);
      padding-bottom: size(40);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding-top: size(120);
      padding-bottom: size(60);
    }
    .markdown {
      .p {
        font-weight: bold;
        font-size: size(20);
        line-height: size(26);
        padding-bottom: size(10);
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(24);
          line-height: size(32);
        }
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(30);
          line-height: size(42);
        }
        a {
          color: var(--hero-text);
        }
        strong {
          font-weight: bold;
        }
      }
    }
    .content {
      .p {
        font-size: size(16);
        line-height: size(24);
        font-weight: normal;
        padding-bottom: 0;
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(18);
          line-height: size(28);
        }
        strong {
          font-weight: bold;
        }
      }
    }
    p {
      font-size: size(16);
      line-height: size(24);
      @media screen and (min-width: $breakpoint-md) {
        font-size: size(18);
        line-height: size(28);
      }
    }
  }
}
