@import "@/assets/styles/global/lib.scss";






































































































































































































































































































































































































































































































































































































































































































































































































































































































































.price-table {
  .rs-button {
    &.black-cta {
      > a {
        margin-top: size(4);
      }
    }
  }
}
